<template>
    <v-dialog
        v-model="open"
        width="910"
    >
        <div class="lunchMenuDialog dialogPadding text-center">
            <DialogTop @close="open = false" />

            <div class="header flex justify-center items-center">
                <EventIcon icon="apple" />

                <h2 class="heading">
                    Lunchmeny för vecka {{ week }}
                </h2>
            </div>

            <div v-if="lunchMenu">
                <LunchMenu :menu="lunchMenu" />

                <LunchInfo />
            </div>

            <Content v-if="!lunchMenu">
                <p>
                    Det finns ingen lunchmeny för denna veckan.
                </p>
            </Content>
        </div>
    </v-dialog>
</template>

<script>
import EventIcon from '../calendar/CalendarEventIcon';
import DialogTop from '../ui/DialogTop';
import Content from '../ui/Content';
import LunchMenu from './LunchMenu';
import LunchInfo from './LunchInfo';

export default {
    components: {
        EventIcon,
        DialogTop,
        Content,
        LunchMenu,
        LunchInfo
    },

    props: {
        lunchMenu: {
            type: Object,
            default: null
        },

        week: {
            type: Number,
            required: true
        }
    },

    data: () => ({
        open: true
    }),

    watch: {
        open () {
            if (!this.open) {
                this.$emit('close');
            }
        }
    }
};
</script>

<style lang="postcss" scoped>
.lunchMenuDialog {
    background-color: #fff;
}

.header {
    margin-bottom: 4rem;
    @media (--mobile) {
        margin-bottom: 2.5rem;
    }
}

.heading {
    margin-left: 2rem;
    @media (--mobile) {
        margin-left: 1.5rem;
    }
}
</style>
