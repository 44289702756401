<template>
    <div
        :style="{ 'background-color': color }"
        class="calendarEventIcon inline-flex justify-center items-center flex-shrink-0 rounded-full"
    >
        <component
            :is="iconComponent.component"
            class="icon"
            :class="`icon--${iconComponent.value}`"
        />
    </div>
</template>

<script>
import icons from '@/utils/courseIcons';

export default {
    props: {
        icon: {
            type: String,
            required: true
        },

        color: {
            type: String,
            default: null
        }
    },

    data: () => ({
        icons
    }),

    computed: {
        iconComponent () {
            const icon = this.icons.find(icon => icon.value === this.icon);
            return icon || null;
        }
    }
};
</script>

<style lang="postcss" scoped>
.calendarEventIcon {
    font-size: 4em;
    width: 1em;
    height: 1em;
    color: #fff;
    background-color: #88bcca;
    @media (--mobile) {
        font-size: 3.4em;
    }
}

.icon {
    width: 0.6em;
    height: 0.6em;
}

.icon--test {
    width: 0.5em;
    height: 0.5em;
}
</style>
