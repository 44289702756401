import * as API from '../API.js';

export default {
    all () {
        return API.apiClient.get('/admin/lessons');
    },

    get (lessonId) {
        return API.apiClient.get(`/admin/lessons/${lessonId}`);
    },

    create (payload) {
        return API.apiClient.post('/admin/lessons', payload);
    },

    update (lessonId, payload) {
        return API.apiClient.put(`/admin/lessons/${lessonId}`, payload);
    },

    remove (lessonId) {
        return API.apiClient.delete(`/admin/lessons/${lessonId}`);
    }
};
