<template>
    <div
        :class="{ 'isSmall': small }"
        class="iconPicker form-input inline-block"
    >
        <v-btn-toggle
            :value="value"
            light
            tile
            borderless
            @change="newValue => $emit('input', newValue)"
        >
            <v-btn
                v-for="icon in icons"
                :key="icon.value"
                :value="icon.value"
                class="p-2"
                light
            >
                <component
                    :is="icon.component"
                    :class="[ value === icon.value ? 'text-white' : 'text-black' ]"
                    class="icon"
                />
            </v-btn>
        </v-btn-toggle>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: null
        },

        icons: {
            type: Array,
            required: true
        },

        small: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="postcss" scoped>
.iconPicker {
    padding: 0;
    overflow: hidden;
    line-height: 0;
}

.iconPicker >>> .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: transparent;
    color: var(--primaryColor);
}

.iconPicker >>> .v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 1;
}

.icon {
    width: 3rem;
    height: 3rem;
    @media (--tablet) {
        width: 2.4rem;
        height: 2.4rem;
    }
}

.iconPicker.isSmall .icon {
    width: 2.4rem;
    height: 2.4rem;
    @media (--tablet) {
        width: 2.2rem;
        height: 2.2rem;
    }
}

.iconPicker >>> .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
    height: 4.8rem;
    min-width: 4.8rem;
    padding: 0;
    @media (--tablet) {
        height: 4.2rem;
        min-width: 4.2rem;
    }
}

.iconPicker.isSmall >>> .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
    height: 4.4rem;
    min-width: 4.4rem;
    padding: 0;
    @media (--tablet) {
        height: 4rem;
        min-width: 4rem;
    }
}

.iconPicker .v-btn-toggle {
    flex-wrap: wrap;
}
</style>
