<template>
    <button class="removeButton inline-flex justify-center items-center rounded-full">
        <trashIcon class="removeIcon" />
    </button>
</template>

<script>
import trashIcon from '@/assets/images/trash.svg';

export default {
    components: {
        trashIcon
    }
};
</script>

<style lang="postcss" scoped>
.removeButton {
    width: 1em;
    height: 1em;
    background-color: var(--primaryColor);
    color: #fff;
    margin-right: 1rem;

    @media (--mobile) {
        order: 3;
        margin-right: 0;
        margin-left: 1.5rem;
    }

    &:hover {
        background-color: var(--secondaryColor);
    }
}

.removeIcon {
    width: 0.5em;
    height: 0.5em;
}
</style>
