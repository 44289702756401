import { render, staticRenderFns } from "./LessonFormDialog.vue?vue&type=template&id=b555d838&scoped=true&"
import script from "./LessonFormDialog.vue?vue&type=script&lang=js&"
export * from "./LessonFormDialog.vue?vue&type=script&lang=js&"
import style0 from "./LessonFormDialog.vue?vue&type=style&index=0&id=b555d838&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b555d838",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})
