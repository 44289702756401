<template>
    <v-menu
        ref="menu"
        v-model="datePicker"
        :close-on-content-click="false"
        class="datePicker"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="value"
                readonly
                v-bind="attrs"
                outlined
                hide-details
                dense
                v-on="on"
            />
        </template>

        <v-date-picker
            :value="value"
            :first-day-of-week="1"
            locale="sv-SE"
            color="#ae3a7a"
            no-title
            @input="newValue => {datePicker = false; $emit('input', newValue)}"
        />
    </v-menu>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number],
            default: null
        }
    },

    data: () => ({
        datePicker: null
    })
};
</script>

<style lang="postcss">
.v-picker--date .mdi-chevron-left::before,
.v-picker--date .mdi-chevron-right::before {
    height: 30px;
    position: absolute;
    top: 0;
    font-size: 30px;
    margin-top: -5px;
    transform: translateX(-50%);
    left: 50%;
}

.v-date-picker-header__value button {
    text-transform: capitalize;
    font-size: 1.6rem;
}

.v-picker--date .mdi-chevron-left::before {
    content: '\2039';
}

.v-picker--date .mdi-chevron-right::before {
    content: '\203A';
}

.v-picker--date .v-btn--active .v-btn__content {
    color: #fff !important;
}

.v-date-picker-table {
    height: auto !important;
    padding-bottom: 10px !important;
}
</style>
