<template>
    <div class="lunchMenu">
        <Content>
            <p
                v-for="(day, key) in days"
                :key="key"
                v-html="`<b>${day}</b><br>${menu[key]}`"
            ></p>
        </Content>
    </div>
</template>

<script>
import Content from '../ui/Content';
import { weekdays } from '@/utils/utils';

export default {
    components: {
        Content
    },

    props: {
        menu: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        days: weekdays
    })
};
</script>

<style lang="postcss" scoped>
.content b {
    color: var(--secondaryColor);
}
</style>
