var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar",class:{ isLoading: _vm.isLoading }},[_c('WeekNavigation',{attrs:{"month":_vm.selectedMonth,"week":_vm.selectedWeek},on:{"prev":function($event){return _vm.prevWeek()},"next":function($event){return _vm.nextWeek()}}}),_c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
            left: function () { return _vm.next(); },
            right: function () { return _vm.prev(); }
        }),expression:"{\n            left: () => next(),\n            right: () => prev()\n        }"}],staticClass:"calendarWrapper relative"},[_c('v-calendar',{ref:"calendar",staticClass:"whiteBox",attrs:{"weekdays":_vm.weekday,"type":_vm.styles[_vm.size].type,"interval-height":_vm.styles[_vm.size].intervalHeight,"interval-width":_vm.styles[_vm.size].intervalWidth,"events":_vm.events,"event-overlap-mode":_vm.mode,"event-overlap-threshold":30,"short-intervals":false,"short-weekdays":false,"first-time":"07:00","interval-count":"11","locale":"sv"},on:{"change":_vm.handleChange,"click:time":_vm.handleClickOnTime},scopedSlots:_vm._u([(_vm.size === 'small')?{key:"day-label-header",fn:function(ref){
        var date = ref.date;
        var present = ref.present;
return [_c('div',{staticClass:"dayLabelHeader flex items-center justify-center"},[_c('button',{staticClass:"arrowButton arrowButton--prev",attrs:{"type":"button"},on:{"click":function($event){return _vm.prev()}}},[_c('ArrowIcon',{staticClass:"w-full h-full"})],1),_c('span',{staticClass:"dayLabelHeaderDate mx-4",class:{ isToday: present },attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.getDayName(date))+" ")]),_c('button',{staticClass:"arrowButton arrowButton--next",on:{"click":function($event){return _vm.next()}}},[_c('ArrowIcon',{staticClass:"w-full h-full"})],1)])]}}:null,{key:"day-body",fn:function(ref){
        var date = ref.date;
return [(_vm.dateToday === date)?_c('CurrentTimeIndicator',{attrs:{"top":_vm.nowY}}):_vm._e()]}},{key:"event",fn:function(ref){
        var event = ref.event;
        var eventParsed = ref.eventParsed;
return [_c('CalendarEvent',{attrs:{"name":event.name,"icon":event.icon,"classroom":event.classroom,"start":eventParsed.start.time,"end":eventParsed.end.time,"color":event.color},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.handleClickOnEvent(event, eventParsed)}}})]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),(_vm.isLoading)?_c('div',{staticClass:"loadingOverlay fadeIn ab100"},[_c('Spinner')],1):_vm._e()],1),(_vm.dialog && _vm.dialog.type === 'lesson')?_c('CalendarDialog',{attrs:{"event":_vm.dialog},on:{"close":function($event){_vm.dialog = null},"lessonRemoved":_vm.handleLessonRemoved,"editLesson":function (id) { return _vm.$emit('editLesson', id); }}}):_vm._e(),(_vm.dialog && _vm.dialog.type === 'lunch')?_c('LunchMenuDialog',{attrs:{"lunch-menu":_vm.lunchMenu,"week":_vm.selectedWeek},on:{"close":function($event){_vm.dialog = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }