import * as API from './API.js';

export default {
    get (payload) {
        return API.apiClient.get('/calendar', {
            params: payload
        });
    },

    reminders () {
        return API.apiClient.get('/reminders');
    }
};
