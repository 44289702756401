import { render, staticRenderFns } from "./CalendarEvent.vue?vue&type=template&id=bf3f812a&scoped=true&"
import script from "./CalendarEvent.vue?vue&type=script&lang=js&"
export * from "./CalendarEvent.vue?vue&type=script&lang=js&"
import style0 from "./CalendarEvent.vue?vue&type=style&index=0&id=bf3f812a&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf3f812a",
  null
  
)

export default component.exports