<template>
    <div
        :class="{ isLoading: isLoading }"
        class="calendar"
    >
        <WeekNavigation
            :month="selectedMonth"
            :week="selectedWeek"
            @prev="prevWeek()"
            @next="nextWeek()"
        />

        <div
            v-touch="{
                left: () => next(),
                right: () => prev()
            }"
            class="calendarWrapper relative"
        >
            <v-calendar
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                :type="styles[size].type"
                :interval-height="styles[size].intervalHeight"
                :interval-width="styles[size].intervalWidth"
                :events="events"
                :event-overlap-mode="mode"
                :event-overlap-threshold="30"
                :short-intervals="false"
                :short-weekdays="false"
                first-time="07:00"
                interval-count="11"
                locale="sv"
                class="whiteBox"
                @change="handleChange"
                @click:time="handleClickOnTime"
            >
                <template
                    v-if="size === 'small'"
                    v-slot:day-label-header="{ date, present }"
                >
                    <div
                        class="dayLabelHeader flex items-center justify-center"
                    >
                        <button
                            type="button"
                            class="arrowButton arrowButton--prev"
                            @click="prev()"
                        >
                            <ArrowIcon class="w-full h-full" />
                        </button>

                        <span
                            type="button"
                            :class="{ isToday: present }"
                            class="dayLabelHeaderDate mx-4"
                        >
                            {{ getDayName(date) }}
                        </span>

                        <button
                            class="arrowButton arrowButton--next"
                            @click="next()"
                        >
                            <ArrowIcon class="w-full h-full" />
                        </button>
                    </div>
                </template>

                <template v-slot:day-body="{ date }">
                    <CurrentTimeIndicator
                        v-if="dateToday === date"
                        :top="nowY"
                    />
                </template>

                <template v-slot:event="{ event, eventParsed }">
                    <CalendarEvent
                        :name="event.name"
                        :icon="event.icon"
                        :classroom="event.classroom"
                        :start="eventParsed.start.time"
                        :end="eventParsed.end.time"
                        :color="event.color"
                        @click.native.stop="handleClickOnEvent(event, eventParsed)"
                    />
                </template>
            </v-calendar>

            <div
                v-if="isLoading"
                class="loadingOverlay fadeIn ab100"
            >
                <Spinner />
            </div>
        </div>

        <CalendarDialog
            v-if="dialog && dialog.type === 'lesson'"
            :event="dialog"
            @close="dialog = null"
            @lessonRemoved="handleLessonRemoved"
            @editLesson="id => $emit('editLesson', id)"
        />

        <LunchMenuDialog
            v-if="dialog && dialog.type === 'lunch'"
            :lunch-menu="lunchMenu"
            :week="selectedWeek"
            @close="dialog = null"
        />
    </div>
</template>

<script>
import getISOWeek from 'date-fns/getISOWeek';
import getYear from 'date-fns/getYear';
import CalendarService from '@/services/CalendarService';
import CurrentTimeIndicator from './CurrentTimeIndicator';
import CalendarEvent from './CalendarEvent';
import CalendarDialog from './CalendarDialog';
import LunchMenuDialog from '@/components/lunchMenu/LunchMenuDialog';
import WeekNavigation from '@/components/ui/WeekNavigation';
import Spinner from '@/components/ui/Spinner';
import ArrowIcon from '@/assets/images/chevron.svg';
import setISOWeekYear from 'date-fns/setISOWeekYear';
import setISOWeek from 'date-fns/setISOWeek';
import setDay from 'date-fns/setDay';
import getDay from 'date-fns/getDay';
import setISODay from 'date-fns/setISODay';
import parseISO from 'date-fns/parseISO';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import { mapGetters } from 'vuex';

export default {
    components: {
        WeekNavigation,
        Spinner,
        CurrentTimeIndicator,
        CalendarEvent,
        CalendarDialog,
        LunchMenuDialog,
        ArrowIcon
    },

    props: {
        userId: {
            type: Number,
            default: null
        }
    },

    data: () => ({
        ready: false,
        size: 'large',
        mode: 'column',
        weekday: [1, 2, 3, 4, 5],
        value: new Date(),
        events: [],
        dateToday: new Date().toISOString().split('T')[0],
        selectedYear: null,
        selectedMonth: null,
        selectedWeek: null,
        updateTimeInterval: null,
        dialog: null,
        lunchMenu: null,
        isLoading: false,
        styles: {
            small: {
                type: 'day',
                intervalHeight: 56,
                intervalWidth: 38
            },
            medium: {
                type: 'week',
                intervalHeight: 64,
                intervalWidth: 40
            },
            large: {
                type: 'week',
                intervalHeight: 80,
                intervalWidth: 60
            }
        }
    }),

    computed: {
        calendar () {
            return this.ready ? this.$refs.calendar : null;
        },

        nowY () {
            if (!this.calendar) {
                return '-10px';
            }

            return `${this.calendar.timeToY(this.calendar.times.now)}px`;
        },

        ...mapGetters('auth', ['isAdmin'])
    },

    async mounted () {
        this.watchMediaQueries();

        this.ready = true;
        this.updateTime();
    },

    beforeDestroy () {
        if (this.updateTimeInterval) {
            clearInterval(this.updateTimeInterval);
        }
    },

    methods: {
        watchMediaQueries () {
            const mediaQueries = [
                {
                    watcher: window.matchMedia('(max-width: 760px)'),
                    value: 'small'
                },
                {
                    watcher: window.matchMedia(
                        '(min-width: 761px) and (max-width: 1030px)'
                    ),
                    value: 'medium'
                },
                {
                    watcher: window.matchMedia('(min-width: 1031px)'),
                    value: 'large'
                }
            ];

            const screenTest = event => {
                if (event.matches) {
                    const size = mediaQueries.find(
                        mq => mq.watcher.media === event.media
                    ).value;

                    if (this.size === 'small') {
                        const day = getDay(this.value);

                        if (day === 0 || day === 6) {
                            this.value = setDay(this.value, 5);
                        }
                    }

                    this.size = size;
                }
            };

            mediaQueries.forEach(mq => {
                screenTest(mq.watcher);
                mq.watcher.addListener(screenTest);
            });

            this.$once('hook:beforeDestroy', () => {
                mediaQueries.forEach(mq => {
                    mq.watcher.removeListener(screenTest);
                });
            });
        },

        async getEvents (year, week) {
            this.isLoading = true;
            this.events = [];
            this.lunchMenu = null;

            const res = await CalendarService.get({
                week,
                year,
                userId: this.userId
            });

            const lessons = res.data.lessons.map(event => {
                const { start, end } = this.getEventDate(event);

                return {
                    id: event.id,
                    name: event.course.name,
                    start: start,
                    end: end,
                    icon: event.course.icon,
                    courseCode: event.course.code,
                    classroom: event.classroom.name,
                    information: event.information,
                    links: event.links,
                    teachers: event.teachers,
                    timed: true,
                    type: 'lesson',
                    color: event.course.color
                };
            });

            const lunchEvents = res.data.lunchEvents.map(lunchEvent => {
                const { start, end } = this.getEventDate(lunchEvent);

                return {
                    name: 'Lunch',
                    start: start,
                    end: end,
                    icon: 'apple',
                    timed: true,
                    type: 'lunch',
                    color: '#cab888'
                };
            });

            this.lunchMenu = res.data.lunchMenu;

            this.events = [...lessons, ...lunchEvents];

            this.isLoading = false;
        },

        reload () {
            this.getEvents(this.selectedYear, this.selectedWeek);
        },

        getEventDate (event) {
            const start = parseISO(event.start);
            const end = parseISO(event.end);

            if (!event.repeat_until) {
                return { start, end };
            }

            const dayOfWeek = getDay(start);

            const setDate = date => {
                const hours = getHours(date);
                const minutes = getMinutes(date);

                date = setISOWeekYear(date, this.selectedYear);
                date = setISOWeek(date, this.selectedWeek);
                date = setISODay(date, dayOfWeek);
                date = setHours(date, hours);
                date = setMinutes(date, minutes);

                return date;
            };

            if (setDate(start) > setHours(parseISO(event.repeat_until), 23)) {
                return { start, end };
            }

            return { start: setDate(start), end: setDate(end) };
        },

        handleClickOnEvent (event, eventParsed) {
            this.dialog = {
                ...event,
                start: eventParsed.start,
                end: eventParsed.end
            };
        },

        handleChange ({ start, end }) {
            const startDate = new Date(start.date);

            const year = getYear(startDate);
            const month = startDate.toLocaleDateString('sv-SE', {
                month: 'long'
            });
            const week = getISOWeek(startDate);

            this.selectedYear = year;
            this.selectedMonth = month;
            this.selectedWeek = week;

            this.getEvents(year, week);
        },

        updateTime () {
            this.updateTimeInterval = setInterval(
                () => this.calendar.updateTimes(),
                60 * 1000
            );
        },

        prev () {
            this.calendar.prev();
        },

        next () {
            this.calendar.next();
        },

        getDayName (date) {
            return parseISO(date).toLocaleDateString('sv-SE', {
                weekday: 'long'
            });
        },

        prevWeek () {
            if (this.size !== 'small') {
                this.prev();
                return;
            }

            this.calendar.prev(7);
        },

        nextWeek () {
            if (this.size !== 'small') {
                this.next();
                return;
            }

            this.calendar.next(7);
        },

        handleClickOnTime (event) {
            if (!this.isAdmin) {
                return;
            }

            this.$emit('addNewLesson', event);
        },

        handleLessonRemoved () {
            this.reload();

            this.dialog = null;
        }
    }
};
</script>

<style lang="postcss" scoped>
.calendar >>> .theme--light.v-calendar-daily {
    border-left: 0;
    border-top: 0;
}

.calendar >>> .v-calendar-daily__scroll-area {
    overflow-y: hidden !important;
}

.calendar >>> .v-calendar-daily__pane {
    overflow: hidden;
}

.calendar >>> .v-calendar-daily__head {
    margin-right: 0 !important;
}

.calendar >>> .theme--light.v-calendar-events .v-event-timed {
    border: 0 !important;
    border-radius: 0 !important;
    padding: 0;
    overflow: visible;
}

.calendar >>> .v-calendar-daily__body {
    flex: auto;
}

.calendar >>> .v-calendar-daily_head-day .v-btn {
    background-color: transparent !important;
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0.045rem;
    width: 5rem;
    height: 5rem;
    pointer-events: none;
    cursor: auto;
    @media (--tablet) {
        width: 3rem;
        height: 3rem;
        font-size: 1.4rem;
    }
}

.calendar >>> .v-calendar-daily_head-day.v-present .v-btn {
    background-color: purple !important;
    color: #fff;
}

.calendar >>> .v-calendar .v-event-timed-container {
    margin-right: 0;
}

.calendar >>> .v-calendar-daily_head-day {
    display: flex;
    flex-direction: column-reverse;
    padding: 1rem 0;
    font-size: 1.6rem;
    letter-spacing: 0.032rem;
    pointer-events: none;
    @media (--tablet) {
        padding: 0.8rem;
        font-size: 1.5rem;
        line-height: 1.3;
    }
    @media (--smallTablet) {
        pointer-events: auto;
    }
}

.calendar >>> .v-calendar-daily_head-weekday {
    font-size: 1.6rem;
    text-transform: capitalize;
    padding-top: 0;
    @media (--tablet) {
        font-size: 1.5rem;
    }
    @media (--smallTablet) {
        display: none;
    }
}

.calendar >>> .theme--light.v-calendar-daily .v-calendar-daily_head-day {
    color: var(--secondaryColor);

    &:last-child {
        border-right: 0;
    }
}

.calendar >>> .theme--light.v-calendar-daily .v-calendar-daily__day {
    border-bottom: 0;
}

.calendar >>> .theme--light.v-calendar-daily .v-calendar-daily__day:last-child {
    border-right: 0;
}

.calendar >>> .v-calendar-daily__day.v-past {
    opacity: 0.5;
}

.calendar >>> .v-calendar-daily__interval-text,
.calendar >>> .v-calendar-daily__interval {
    @media (--tablet) {
        padding-right: 0 !important;
        text-align: center !important;
    }
}

.calendar >>> .v-calendar-daily__interval-text {
    @media (--tablet) {
        font-size: 9px;
    }
}

.calendar >>> .v-calendar-daily__interval::after {
    @media (--tablet) {
        width: 0;
    }
}

.calendar >>> .v-calendar-daily_head-day-label {
    @media (--smallTablet) {
        padding: 0;
    }
}

.arrowButton {
    color: var(--secondaryColor);
    width: 2.4rem;
    height: 2.4rem;
}

.arrowButton--prev {
    transform: rotate(0.25turn);
}

.arrowButton--next {
    transform: rotate(-0.25turn);
}

.dayLabelHeader {
    cursor: auto;
    padding: 1rem 0;
    padding-right: 19px;
}

.dayLabelHeaderDate {
    width: 90px;
    text-align: center;
    margin: 0 1.2rem;
    transition: var(--normalTrans);
    font-weight: bold;

    &.isToday {
        color: var(--primaryColor);
    }

    &::first-letter {
        text-transform: uppercase;
    }
}

.loadingOverlay {
    background: rgb(255, 255, 255, 0.75);
    z-index: 5;
    border-radius: 1.4rem;
}

.spinner {
    position: absolute;
    top: 20rem;
    left: 50%;
    transform: translateX(-50%);
    width: 4rem;
    height: 4rem;
    color: var(--primaryColor);
    z-index: 6;
}
</style>
