import LessonService from '@/services/admin/LessonService';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import formatISO9075 from 'date-fns/formatISO9075';
import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';

export default {
    async getLesson () {
        if (!this.lessonId) {
            return {
                course_id: null,
                classroom_id: null,
                date: null,
                startHour: 0,
                startMinute: 0,
                repeat_until: null,
                endHour: 0,
                endMinute: 0,
                users: [],
                teachers: [],
                information: null,
                links: []
            };
        }

        const res = await LessonService.get(this.lessonId);

        const lesson = res.data;

        lesson.teachers = lesson.teachers.map(teacher => teacher.id);
        lesson.users = lesson.users.map(user => user.id);

        const startDate = parseISO(lesson.start);
        const endDate = parseISO(lesson.end);

        lesson.date = formatISO(startDate, { representation: 'date' });

        lesson.startHour = getHours(startDate);
        lesson.startMinute = getMinutes(startDate);

        lesson.endHour = getHours(endDate);
        lesson.endMinute = getMinutes(endDate);

        return lesson;
    },

    addLink (row) {
        this.lesson.links.push({
            id: row.id,
            title: null,
            url: null,
            icon: null
        });
    },

    setLessonDates () {
        if (!this.lesson.date) {
            this.lesson.start = null;
            this.lesson.end = null;

            return;
        }

        const date = parseISO(this.lesson.date);

        const startDate = setHours(date, this.lesson.startHour);
        const endDate = setHours(date, this.lesson.endHour);

        this.lesson.start = formatISO9075(setMinutes(startDate, this.lesson.startMinute));
        this.lesson.end = formatISO9075(setMinutes(endDate, this.lesson.endMinute));
    },

    async handleSubmit () {
        this.loading = true;

        this.setLessonDates();

        try {
            if (this.lessonId) {
                await this.update();
            } else {
                await this.create();
            }
        } catch (error) {
            // eslint-disable-next-line
            console.log(error);
        }

        this.loading = false;
    }
};
