<template>
    <v-select
        :value="value"
        :items="items"
        :placeholder="placeholder"
        :menu-props="{
            'content-class': 'selectFieldMenu multiple',
            'open-on-focus': true
        }"
        item-text="name"
        item-value="id"
        class="multipleSelectField"
        color="#0a1032"
        multiple
        outlined
        hide-details
        chips
        small-chips
        @change="newValue => $emit('input', newValue)"
    >
        <template v-slot:prepend-item>
            <v-list-item
                ripple
                @click="toggleSelectAll"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        <b>{{ allSelected ? 'Avmarkera alla' : 'Välj alla' }}</b>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="mt-2" />
        </template>

        <template v-slot:append>
            <ChevronIcon class="icon text-gray-700" />
        </template>

        <template v-slot:selection="{ item }">
            <v-chip
                style="cursor: pointer"
                small
            >
                {{ item.name }}
            </v-chip>
        </template>
    </v-select>
</template>

<script>
import ChevronIcon from '@/assets/images/chevron.svg';

export default {
    components: {
        ChevronIcon
    },

    props: {
        value: {
            type: Array,
            default: null
        },

        items: {
            type: Array,
            required: true
        },

        placeholder: {
            type: String,
            default: null
        }
    },

    computed: {
        allSelected () {
            return this.value && this.value.length === this.items.length;
        }
    },

    methods: {
        toggleSelectAll () {
            this.$nextTick(() => {
                if (this.allSelected) {
                    this.$emit('input', []);
                } else {
                    this.$emit('input', this.items.map(item => item.id));
                }
            });
        }
    }
};
</script>

<style lang="postcss" scoped>
.icon {
    width: 2.4rem;
    height: 2.4rem;
}

.multipleSelectField >>> .theme--light.v-chip {
    background-color: var(--secondaryColor) !important;
    color: #fff;
}

.multipleSelectField .v-chip.v-size--small {
    border-radius: 1.2rem;
    font-size: 1.2rem;
    height: 2.4rem;
    margin: 0.4rem;
    padding: 0 1.2rem;
    line-height: 2rem;
}
</style>
