import Vue from 'vue';
import * as API from '../API.js';
import store from '@/store';

export default {
    all () {
        return API.apiClient.get('/admin/users');
    },

    students () {
        return API.apiClient.get('/admin/users/students');
    },

    get (userId) {
        return API.apiClient.get(`/admin/users/${userId}`);
    },

    create (payload) {
        return API.apiClient.post('/admin/users', payload);
    },

    update (userId, payload) {
        return API.apiClient.put(`/admin/users/${userId}`, payload);
    },

    remove (userId) {
        const authedUser = store.getters['auth/authUser'];

        if (userId === authedUser.id) {
            Vue.toasted.error(
                'Det går inte att ta bort den inloggade användaren.'
            );
            return;
        }

        return API.apiClient.delete(`/admin/users/${userId}`);
    }
};
