<template>
    <div>
        <div class="flex items-center mb-10 pb-5 flex-wrap sm:flex-nowrap">
            <div class="headingHolder flex-shrink-0">
                <AdminTop
                    heading="Visa schema för"
                    class="grow-shrink-0"
                    style="margin-bottom: 0"
                />
            </div>

            <div class="selectHolder mt-4 w-full sm:w-auto sm:ml-10 sm:mt-0">
                <SelectField
                    v-if="users"
                    v-model="userId"
                    :items="users"
                    placeholder="Välj elev"
                    class="bg-white"
                    :show-search="true"
                />
            </div>

            <div
                v-if="userId"
                class="addNewHolder mt-4 sm:ml-10 sm:mt-0"
            >
                <Button
                    label="Lägg till lektion"
                    @click="addNewLesson"
                />
            </div>
        </div>

        <LargeColumnWithSidebar
            v-if="userId"
            :key="userId"
            :hide-sidebar-small-device="false"
        >
            <Calendar
                ref="calendar"
                :user-id="userId"
                @addNewLesson="addNewLesson"
                @editLesson="editLesson"
            />

            <template v-slot:sidebar>
                <Reminders :user-id="userId" />
            </template>
        </LargeColumnWithSidebar>

        <LessonFormDialog
            :open="lessonDialogOpen"
            :user-id="userId"
            :initial-date-time="lessonDialogInitialDateTime"
            :lesson-id="editLessonId"
            @lessonCreated="handleUpdate"
            @lessonUpdated="handleUpdate"
            @close="closeLessonDialog"
        />
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import LargeColumnWithSidebar from '@/components/layout/LargeColumnWithSidebar';
import Calendar from '@/components/calendar/Calendar';
import Reminders from '@/components/reminders/Reminders';
import SelectField from '@/components/form/SelectField';
import UserService from '@/services/admin/UserService';
import Button from '@/components/ui/Button.vue';
import LessonFormDialog from '@/components/admin/LessonFormDialog';

export default {
    components: {
        AdminTop,
        LargeColumnWithSidebar,
        Calendar,
        Reminders,
        SelectField,
        Button,
        LessonFormDialog
    },

    data: () => ({
        userId: null,
        users: null,
        lessonDialogOpen: false,
        lessonDialogInitialDateTime: null,
        editLessonId: null
    }),

    async created () {
        const users = await UserService.students();

        this.users = users.data;
    },

    methods: {
        handleUpdate () {
            this.lessonDialogOpen = false;
            this.editLessonId = null;

            this.$refs.calendar.reload();
        },

        addNewLesson (dateTime = null) {
            this.lessonDialogInitialDateTime = dateTime;

            this.lessonDialogOpen = true;
        },

        editLesson (id) {
            this.editLessonId = id;
            this.lessonDialogOpen = true;
        },

        closeLessonDialog () {
            this.editLessonId = null;
            this.lessonDialogOpen = false;
        }
    }
};
</script>

<style lang="postcss" scoped>
.reminders >>> .top {
    @media (--smallTablet) {
        display: none;
    }
    @media (--mobile) {
        display: block;
        min-height: 0;
    }
}

.headingHolder >>> h2 {
    font-size: 2rem;
}

.selectHolder {
    padding-right: 6rem;

    @media (min-width: 440px) {
        padding-right: 10rem;
    }

    @media (min-width: 640px) {
        width: 32rem;
        padding-right: 0;
    }
}
</style>
