<template>
    <div class="repeater bg-gray-100">
        <draggable
            :list="rows"
            handle=".moveButton"
            ghost-class="ghost"
        >
            <transition-group tag="div">
                <RepeaterRow
                    v-for="row in rows"
                    :key="row.id"
                    @remove="removeRow(row)"
                >
                    <template v-slot>
                        <slot :row="row" />
                    </template>
                </RepeaterRow>
            </transition-group>
        </draggable>

        <div class="buttonHolder flex flex-wrap justify-end">
            <Button
                :label="addLabel"
                :small="true"
                class="addButton"
                @click="addRow"
            />
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import Button from '@/components/ui/Button.vue';
import RepeaterRow from './RepeaterRow.vue';

export default {
    components: {
        draggable,
        Button,
        RepeaterRow
    },

    props: {
        rows: {
            type: Array,
            required: true
        },

        addLabel: {
            type: String,
            default: 'Lägg till rad'
        }
    },

    methods: {
        addRow () {
            this.$emit('addRow', { id: Date.now() });
        },

        removeRow (row) {
            const index = this.rows.indexOf(row);
            if (index !== -1) {
                this.$emit('removeRow', index);
            }
        }
    }
};
</script>

<style lang="postcss" scoped>
.repeater,
.repeaterRow {
    border: 1px solid #718096;
    padding: 2.5rem;
    border-radius: 0.375rem;
}

.addButton.button {
    font-size: 1rem;
    padding: 0.8rem;
    width: 11rem;
    background-color: var(--secondaryColor);
    &:hover {
        background-color: var(--primaryColor);
    }
}
</style>
