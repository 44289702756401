<template>
    <div class="lunchInfo">
        <Content>
            <p>
                <i>
                    Med reservation för ändringar. Till följd av personalbrist serveras vegetariskt till de som vanligtvis äter fläskfri kost.
                    De elever som behöver laktosfri kost serveras tills vidare mjölkproteinfri kost, laktosfritt smör och laktosfri mjölk kommer fortsättningsvis serveras.
                </i>
            </p>
        </Content>
    </div>
</template>

<script>
import Content from '@/components/ui/Content';

export default {
    components: {
        Content
    }
};
</script>

<style lang="postcss" scoped>
.lunchInfo {
    margin-top: 3rem;
    @media (--laptop) {
        margin-top: 2.5rem;
    }
    @media (--mobile) {
        margin-top: 2rem;
    }
}
</style>
