<template>
    <button class="editButton inline-flex justify-center items-center rounded-full">
        <editIcon class="editIcon" />
    </button>
</template>

<script>
import editIcon from '@/assets/images/pen.svg';

export default {
    components: {
        editIcon
    }
};
</script>

<style lang="postcss" scoped>
.editButton {
    width: 1em;
    height: 1em;
    background-color: var(--primaryColor);
    color: #fff;
    margin-right: 1rem;

    @media (--mobile) {
        order: 2;
        margin-right: 0;
        margin-left: auto;
    }

    &:hover {
        background-color: var(--secondaryColor);
    }
}

.editIcon {
    width: 0.45em;
    height: 0.45em;
}
</style>
