import { render, staticRenderFns } from "./Calendar.vue?vue&type=template&id=1ff6bea6&scoped=true&"
import script from "./Calendar.vue?vue&type=script&lang=js&"
export * from "./Calendar.vue?vue&type=script&lang=js&"
import style0 from "./Calendar.vue?vue&type=style&index=0&id=1ff6bea6&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ff6bea6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCalendar } from 'vuetify/lib/components/VCalendar';
installComponents(component, {VCalendar})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Touch from 'vuetify/lib/directives/touch'
installDirectives(component, {Touch})
