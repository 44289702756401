<template>
    <div class="flex items-center">
        <div class="w-16">
            <v-btn
                icon
                @click="onAddHourClicked"
            >
                <ChevronIcon class="icon up text-gray-700" />
            </v-btn>

            <v-text-field
                v-model="hourModel"
                type="number"
                :min="minHour"
                :max="maxHour"
                step="1"
                class="numberInput"
                required
                hide-details
            />

            <v-btn
                icon
                @click="onSubtractHourClicked"
            >
                <ChevronIcon class="icon down text-gray-700" />
            </v-btn>
        </div>

        <span class="seperator">
            :
        </span>

        <div class="w-16">
            <v-btn
                icon
                @click="onAddMinuteClicked"
            >
                <ChevronIcon class="icon up text-gray-700" />
            </v-btn>

            <v-text-field
                v-model="minuteModel"
                type="number"
                :min="minMinute"
                :max="maxMinute"
                step="1"
                class="numberInput"
                required
                hide-details
            />

            <v-btn
                icon
                @click="onSubtractMinuteClicked"
            >
                <ChevronIcon class="icon down text-gray-700" />
            </v-btn>
        </div>
    </div>
</template>

<script>
import ChevronIcon from '@/assets/images/chevron.svg';

export default {
    components: {
        ChevronIcon
    },

    props: {
        initialHour: {
            type: Number,
            required: true
        },

        initialMinute: {
            type: Number,
            required: true
        }
    },

    data: () => ({
        hour: 0,
        minute: 0,
        minHour: 0,
        maxHour: 23,
        minMinute: 0,
        maxMinute: 59
    }),

    computed: {
        hourModel: {
            get () {
                return this.forceTwoDigits(this.hour);
            },
            set (v) {
                this.hour = Number(v);
            }
        },

        minuteModel: {
            get () {
                return this.forceTwoDigits(this.minute);
            },
            set (v) {
                this.minute = Number(v);
            }
        }
    },

    watch: {
        initialHour () {
            this.hour = this.initialHour;
        },

        initialMinute () {
            this.minute = this.initialMinute;
        },

        hour: function (hour) {
            this.$nextTick(() => {
                if (hour < this.minHour) this.hour = this.minHour;
                if (hour > this.maxHour) this.hour = this.maxHour;

                this.emitChangedEvent();
            });
        },

        minute: function (minute) {
            this.$nextTick(() => {
                if (minute < this.minMinute) this.minute = this.minMinute;
                if (minute > this.maxMinute) this.minute = this.maxMinute;

                this.emitChangedEvent();
            });
        }
    },

    created () {
        this.hour = this.initialHour;
        this.minute = this.initialMinute;
    },

    methods: {
        emitChangedEvent () {
            this.$emit('changed', this.hour, this.minute);
        },

        forceTwoDigits (num) {
            return (num < 10 ? '0' : '') + num;
        },

        onAddHourClicked () {
            this.hour < this.maxHour ? this.hour += 1 : this.hour = this.minHour;
        },

        onSubtractHourClicked () {
            this.hour > this.minHour ? this.hour -= 1 : this.hour = this.maxHour;
        },

        onAddMinuteClicked () {
            if (this.minute < this.maxMinute) {
                this.minute += 1;
            } else {
                this.minute = this.minMinute;
                this.onAddHourClicked();
            }
        },

        onSubtractMinuteClicked () {
            if (this.minute > this.minMinute) {
                this.minute -= 1;
            } else {
                this.minute = this.maxMinute;
                this.onSubtractHourClicked();
            }
        }
    }
};
</script>

<style lang="postcss" scoped>
.numberInput {
    padding: 0.3rem 0 1.2rem;
    margin: 0 auto;
    width: 22px;
}

.seperator {
    font-size: 2.4rem;
    margin: 0 0.5rem 0.2rem;
}

.icon {
    width: 2rem;
    height: 2rem;
    &.up {
        transform: rotate(0.5turn);
    }
}

.numberInput >>> input::-webkit-outer-spin-button,
.numberInput >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numberInput >>> input[type=number] {
  -moz-appearance: textfield;
}
</style>
