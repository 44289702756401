<template>
    <div
        :class="{ isSmall }"
        class="calendarEvent fadeIn transition"
    >
        <div class="wrapper relative overflow-hidden">
            <div class="inner flex">
                <EventIcon
                    :icon="icon"
                    :color="color"
                />

                <div class="textHolder">
                    <SmallHeading :heading="name" />

                    <p class="info">
                        {{ start }}-{{ end }}
                        <span v-if="classroom">
                            <span class="infoSeperator big">i sal</span><span class="infoSeperator small">/</span>
                            {{ classroom }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventIcon from './CalendarEventIcon';
import SmallHeading from '../ui/SmallHeading';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setSeconds from 'date-fns/setSeconds';
import differenceInMinutes from 'date-fns/differenceInMinutes';

export default {
    components: {
        EventIcon,
        SmallHeading
    },

    props: {
        name: {
            type: String,
            required: true
        },

        start: {
            type: String,
            required: true
        },

        end: {
            type: String,
            required: true
        },

        icon: {
            type: String,
            required: true
        },

        color: {
            type: String,
            default: null
        },

        classroom: {
            type: String,
            default: null
        }
    },

    computed: {
        isSmall () {
            const date = setSeconds(new Date(), 0);

            const start = setMinutes(
                setHours(date, this.start.split(':')[0]),
                this.start.split(':')[1]
            );
            const end = setMinutes(
                setHours(date, this.end.split(':')[0]),
                this.end.split(':')[1]
            );

            const diffrence = differenceInMinutes(end, start);

            return diffrence < 45;
        }
    }
};
</script>

<style lang="postcss" scoped>
.calendarEvent {
    font-size: 0.9rem;
    background-color: #f5f5f5;
    padding: 0 1em;
    z-index: 2;
    border: 1px solid #e0e0e0;
    width: calc(100% + 2px);
    margin-left: -1px;
    margin-top: -1px;
    height: calc(100% + 2px);
    position: relative;
    z-index: 1;
    @media (--smallDesktop) {
        font-size: 0.85rem;
    }
    @media (--laptop) {
        font-size: 0.8rem;
    }
    @media (--tablet) {
        font-size: 0.75rem;
        padding: 0 0.5em;
    }
    @media (--smallTablet) {
        font-size: 0.85rem;
        padding: 0 1.2em;
    }

    &:hover {
        background-color: #e8e8e8;
    }
}

.calendarEvent.isSmall {
    font-size: 0.8rem;
    @media (--tablet) {
        font-size: 0.75rem;
    }
    @media (--smallTablet) {
        font-size: 0.85rem;
    }

    & .smallHeading {
        line-height: 1.3;
        @media (--tablet) {
            line-height: 1.2;
        }
        @media (--smallTablet) {
            line-height: 1.3;
        }
    }
    & .calendarEventIcon {
        font-size: 3.5em;
    }
}

.wrapper {
    height: 100%;
    width: 100%;
}

.inner {
    align-items: center;
    height: 100%;
    width: 100%;
}

.calendarEventIcon {
    margin-right: 0.25em;
    @media (--tablet) {
        font-size: 3.5em;
    }
    @media (--mobile) {
        margin-right: 0.35em;
    }
}

.smallHeading {
    font-size: 1.6em;
    @media (--tablet) {
        font-size: 1.5em;
        line-height: 1.3;
        margin-bottom: 0.1em;
    }
    @media (--smallTablet) {
        margin-bottom: 0.15em;
    }
}

.info {
    font-size: 1.3em;
    letter-spacing: 0.025rem;
    color: #898989;
    margin-bottom: 0;
    @media (--tablet) {
        font-size: 1.2em;
    }
}

.infoSeperator.small {
    display: none;
    @media (--tablet) {
        display: inline;
    }
    @media (--smallTablet) {
        display: none;
    }
}

.infoSeperator.big {
    @media (--tablet) {
        display: none;
    }
    @media (--smallTablet) {
        display: inline;
    }
}
</style>
