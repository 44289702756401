<template>
    <button class="closeButton inline-flex justify-center items-center rounded-full">
        <closeIcon class="closeIcon cross" />
        <chevronIcon class="closeIcon chevron flex-shrink-0" />
        <span class="label">Tillbaka</span>
    </button>
</template>

<script>
import closeIcon from '@/assets/images/close.svg';
import chevronIcon from '@/assets/images/chevron.svg';

export default {
    components: {
        closeIcon,
        chevronIcon
    }
};
</script>

<style lang="postcss" scoped>
.closeButton {
    width: 1em;
    height: 1em;
    background-color: var(--primaryColor);
    color: #fff;

    @media (--mobile) {
        width: auto;
        height: auto;
        background-color: transparent;
        color: var(--secondaryColor);
        font-size: 1.6rem;
        font-weight: bold;
    }

    &:hover {
        background-color: var(--secondaryColor);
        @media (--mobile) {
            background-color: transparent;
        }
    }
}

.closeIcon {
    width: 0.6em;
    height: 0.6em;
    @media (--mobile) {
        width: 1.6em;
        height: 1.6em;
    }
}

.cross {
    @media (--mobile) {
        display: none;
    }
}

.chevron,
.label {
    display: none;
    @media (--mobile) {
        display: block;
    }
}

.label {
    margin-top: 1px;
}

.chevron {
    transform: rotate(0.25turn);
    margin-right: 0.35em;
}
</style>
