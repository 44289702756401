<template>
    <div class="repeaterRow">
        <div class="buttons flex absolute">
            <button
                type="button"
                class="button moveButton"
            >
                <moveIcon class="icon" />
            </button>

            <button
                type="button"
                class="button deleteButton"
                @click="$emit('remove')"
            >
                <deleteIcon class="icon" />
            </button>
        </div>

        <slot />
    </div>
</template>

<script>
import moveIcon from '@/assets/images/move.svg';
import deleteIcon from '@/assets/images/trash.svg';

export default {
    components: {
        moveIcon,
        deleteIcon
    }
};
</script>

<style lang="postcss" scoped>
.repeaterRow {
    position: relative;
    background-color: #fff;
    margin-bottom: 2.5rem;
    &.ghost {
        opacity: 0.5;
    }
}

.buttons {
    right: 1rem;
    top: 1rem;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border: 1px solid #718096;
    background-color: #fff;
    border-radius: 0.375rem;
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
}

.deleteButton:hover {
    background-color: #e53e3e;
    border-color: #e53e3e;
    color: #fff;
}

.moveButton {
    cursor: move;
}

.icon {
    width: 1.4rem;
    height: 1.4rem;
}

.repeaterRow:hover .deleteButton {
    opacity: 1;
}
</style>
