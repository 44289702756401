<template>
    <div
        class="currentTime"
        :style="{ top }"
    ></div>
</template>

<script>
export default {
    props: {
        top: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="postcss" scoped>
.currentTime {
    height: 2px;
    background-color: purple;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;
    &::before {
        content: '';
        position: absolute;
        background-color: purple;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: -5px;
        margin-left: -6.5px;
        z-index: 3;
        @media (--tablet) {
            width: 10px;
            height: 10px;
            margin-top: -4px;
            margin-left: -4px;
        }
        @media (--mobile) {
            width: 8px;
            height: 8px;
            margin-top: -3px;
            margin-left: -3px;
        }
    }
}
</style>
