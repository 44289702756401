<template>
    <v-dialog
        v-model="open"
        width="910"
    >
        <div class="calendarDialog flex">
            <div class="leftColumn dialogPadding flex-shrink-0">
                <div class="leftColumn__inner flex">
                    <div class="leftColumn__iconHolder flex-shrink-0">
                        <EventIcon :icon="event.icon" />
                    </div>

                    <div class="leftColumn__textHolder w-full">
                        <h2 class="heading">
                            {{ event.name }}
                        </h2>

                        <p class="courseCode">
                            Kurskod: {{ event.courseCode }}
                        </p>

                        <p class="time">
                            {{ event.start.time }} - {{ event.end.time }}
                        </p>

                        <div class="infoRow">
                            <h4 class="label">
                                Plats/Sal:
                            </h4>

                            <p>
                                {{ event.classroom }}
                            </p>
                        </div>

                        <div
                            v-if="event.teachers && event.teachers.length > 0"
                            class="infoRow"
                        >
                            <h4 class="label teacherLabel">
                                Lärare
                            </h4>

                            <div
                                v-for="teacher in event.teachers"
                                :key="teacher.id"
                                class="teacher flex items-center"
                            >
                                <div class="teacher__imageHolder relative rounded-full overflow-hidden flex-shrink-0">
                                    <img
                                        class="teacher__image ab100 objectFitCover"
                                        :src="teacher.image"
                                    >
                                </div>

                                <p class="teacher__text">
                                    <b class="block">{{ teacher.name }}</b>

                                    <a
                                        class="break-all inline-block"
                                        :href="`mailto:${teacher.email}`"
                                    >
                                        {{ teacher.email }}
                                    </a>

                                    <a
                                        class="block"
                                        :href="`tel:${teacher.phone}`"
                                    >
                                        {{ teacher.phone }}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="rightColumn dialogPadding flex-grow">
                <DialogTop
                    :show-remove="isAdmin"
                    :show-edit="isAdmin"
                    @remove="removeLesson"
                    @edit="editLesson"
                    @close="open = false"
                />

                <div class="rightColumn__inner">
                    <div class="information">
                        <h4 class="label">
                            Information
                        </h4>

                        <Content>
                            <p v-if="event.information">
                                {{ event.information }}
                            </p>

                            <p v-else>
                                <i>Just nu finns det ingen information för denna aktiviteten...</i>
                            </p>
                        </Content>
                    </div>

                    <div
                        v-if="event.links && event.links.length > 0"
                        class="information"
                    >
                        <h4 class="label linksLabel">
                            Länkar/Dokument:
                        </h4>

                        <div
                            v-for="(link, index) in event.links"
                            :key="index"
                            class="linkList"
                        >
                            <a
                                class="link flex justify-between items-center"
                                :href="link.url"
                                target="_blank"
                            >
                                {{ link.title }}

                                <component
                                    :is="getIcon(link.icon)"
                                    class="link__icon"
                                />
                            </a>
                        </div>
                    </div>

                    <form
                        v-if="!isAdmin && noteLoaded"
                        class="note information"
                        @submit.prevent="updateNote"
                    >
                        <FormGroup
                            label="Anteckning"
                            name="note"
                        >
                            <TextareaField
                                v-model="note"
                                class="noteField"
                                name="note"
                                placeholder="Här kan du skriva en anteckning..."
                            />
                        </FormGroup>

                        <div class="flex justify-end mt-10">
                            <Button
                                :loading="loading"
                                label="Spara"
                                type="submit"
                                class="max-w-xs"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import EventIcon from './CalendarEventIcon';
import DialogTop from '../ui/DialogTop';
import Content from '@/components/ui/Content';
import { mapGetters } from 'vuex';
import * as API from '@/services/API.js';
import documentIcon from '@/assets/images/document.svg';
import linkIcon from '@/assets/images/external-link.svg';
import FormGroup from '../form/FormGroup.vue';
import TextareaField from '../form/TextareaField.vue';
import Button from '@/components/ui/Button';
import LessonService from '@/services/admin/LessonService';
import { confirmRemove } from '@/utils/utils.js';

export default {
    components: {
        EventIcon,
        DialogTop,
        Content,
        documentIcon,
        linkIcon,
        FormGroup,
        TextareaField,
        Button
    },

    props: {
        event: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        open: true,
        noteLoaded: false,
        loading: false,
        note: null,
        icons: [
            { component: documentIcon, value: 'document' },
            { component: linkIcon, value: 'external-link' }
        ]
    }),

    computed: {
        ...mapGetters('auth', ['authUser', 'isAdmin'])
    },

    watch: {
        open () {
            if (!this.open) {
                this.$emit('close');
            }
        }
    },

    mounted () {
        this.loadNote();
    },

    methods: {
        getIcon (name) {
            const icon = this.icons.find(icon => icon.value === name);
            return icon.component;
        },

        async loadNote () {
            if (this.isAdmin) {
                return;
            }

            const res = await API.apiClient.get(`/lessons/${this.event.id}/note`);

            if (res && res.data && res.data.text) {
                this.note = res.data.text;
            }

            this.noteLoaded = true;
        },

        async updateNote () {
            this.loading = true;

            try {
                await API.apiClient.post(`/lessons/${this.event.id}/note`, {
                    note: this.note
                });
            } catch (error) {
                // eslint-disable-next-line
                console.log(error);
            }

            this.loading = false;
        },

        async removeLesson () {
            if (!this.isAdmin) {
                return;
            }

            if (!confirmRemove()) {
                return;
            }

            const res = await LessonService.remove(this.event.id);

            if (res && res.status === 200) {
                this.$emit('lessonRemoved');
            }
        },

        editLesson () {
            this.$emit('editLesson', this.event.id);
            this.$emit('close');
        }
    }
};
</script>

<style lang="postcss" scoped>
.calendarDialog {
    background-color: #fff;
    @media (--mobile) {
         display: block;
     }
}

.calendarDialog .content p {
    color: var(--secondaryColor);
}

.leftColumn {
     background-color: #f7f6f6;
     width: 41rem;
     border-right: 1px solid #eee;
     padding-top: 6.4rem;
     @media (--mobile) {
         width: 100%;
         padding-top: 8rem;
         padding-bottom: 3rem;
     }
}

.leftColumn__iconHolder {
    margin-right: 2rem;
}

.rightColumn {
    @media (--mobile) {
        padding-top: 3rem;
        padding-bottom: 6rem;
    }
}

.iconHolder {
    font-size: 6rem;
}

.heading {
    margin-bottom: 0;
}

.courseCode {
    font-size: 1.5rem;
    color: #999;
    letter-spacing: 0.03rem;
    margin-bottom: 1.5rem;
}

.time {
    font-size: 1.8rem;
    letter-spacing: 0.038rem;
    color: var(--secondaryColor);
    margin-bottom: 2rem;
}

.infoRow {
    margin-bottom: 2.5rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.infoRow .label {
    margin-bottom: 0;
}

.infoRow p {
    font-size: 1.4rem;
    color: var(--secondaryColor);
    line-height: 1.6;
}

.infoRow a:hover {
    color: var(--primaryColor);
}

.infoRow .teacherLabel {
    margin-bottom: 1rem;
}

.teacher {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    &:last-child {
        margin-bottom: 0;
    }
}

.teacher__imageHolder {
    font-size: 6rem;
    width: 1em;
    height: 1em;
    margin-right: 1rem;
}

.note >>> label,
.label {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--secondaryColor);
    text-transform: uppercase;
    letter-spacing: 0.025rem;
    line-height: 1.4;
    margin-bottom: 1.5rem;
}

.information {
    margin-bottom: 4.5rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.linksLabel {
    margin-bottom: 0.75rem;
}

.link {
    font-size: 1.6rem;
    color: var(--secondaryColor);
    line-height: 1.6;
    border-bottom: 1px solid #eee;
    padding: 1.25rem 0;

    &:hover {
        color: var(--primaryColor);
    }
}

.link__icon {
    color: var(--primaryColor);
    font-size: 2rem;
    width: 1em;
    height: 1em;
}

.calendarDialog .calendarEventIcon {
    @media (--mobile) {
       font-size: 4.4rem;
    }
}

.noteField {
    height: 24rem;
    border-radius: 0;
    border-color: #eee;
}
</style>
