<template>
    <v-select
        :value="value"
        :items="filteredItems"
        :placeholder="placeholder"
        :menu-props="{ 'content-class': 'selectFieldMenu' }"
        item-text="name"
        item-value="id"
        class="selectField"
        no-data-text="Inga val tillgängliga"
        outlined
        hide-details
        dense
        @change="newValue => $emit('input', newValue)"
    >
        <template
            v-if="showSearch"
            v-slot:prepend-item
        >
            <v-list-item class="searchItem">
                <v-list-item-content>
                    <v-text-field
                        v-model="searchTerm"
                        type="search"
                        placeholder="Sök..."
                        class="searchField"
                        dense
                        hide-details
                    />
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-4" />
        </template>

        <template v-slot:append>
            <ChevronIcon class="icon text-gray-700" />
        </template>
    </v-select>
</template>

<script>
import ChevronIcon from '@/assets/images/chevron.svg';

export default {
    components: {
        ChevronIcon
    },

    props: {
        value: {
            type: [String, Number],
            default: null
        },

        items: {
            type: Array,
            required: true
        },

        placeholder: {
            type: String,
            default: null
        },

        showSearch: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        searchTerm: null
    }),

    computed: {
        filteredItems () {
            if (!this.showSearch || !this.searchTerm) {
                return this.items;
            }

            const items = [...this.items];

            return items.filter(item => {
                return item.name.toLowerCase().search(this.searchTerm.toLowerCase()) !== -1;
            });
        }
    }
};
</script>

<style lang="postcss" scoped>
.icon {
    width: 2.4rem;
    height: 2.4rem;
}

.searchItem >>> .v-list-item__content {
    padding: 0;
}

.searchField {
    margin-top: 0;
}

.searchItem >>> .theme--light.v-text-field > .v-input__control > .v-input__slot:before,
.searchItem >>> .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
    content: none;
}
</style>
