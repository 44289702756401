<template>
    <div class="weekNavigation flex items-center">
        <button
            class="button button--prev"
            type="button"
            @click="$emit('prev')"
        >
            <ArrowIcon class="w-full h-full" />
        </button>

        <div class="middle inline-flex items-center rounded-full">
            <div class="month capitalize">
                <SmallHeading
                    v-if="month"
                    :heading="month"
                />
            </div>

            <p class="week capitalize rounded-full">
                vecka {{ week }}
            </p>
        </div>

        <button
            class="button button--next"
            type="button"
            @click="$emit('next')"
        >
            <ArrowIcon class="w-full h-full" />
        </button>
    </div>
</template>

<script>
import SmallHeading from './SmallHeading';
import ArrowIcon from '@/assets/images/chevron.svg';

export default {
    components: {
        SmallHeading,
        ArrowIcon
    },

    props: {
        month: {
            type: String,
            default: null
        },

        week: {
            type: Number,
            default: null
        }
    }
};
</script>

<style lang="postcss" scoped>
.weekNavigation {
    margin-bottom: 2.5rem;
    @media (--mobile) {
        justify-content: center;
    }
}

.middle {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    padding: 1rem 2rem;
    color: var(--secondaryColor);
    margin: 0 1rem;
}

.month {
    margin-right: 1rem;
    min-width: 9rem;
    @media (--mobile) {
        min-width: 8rem;
    }
}

.month .smallHeading {
    @media (--mobile) {
        font-size: 1.4rem;
    }
}

.week {
    background-color: #f5f6fa;
    padding: 0.25rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.02rem;
}

.button {
    color: var(--primaryColor);
    width: 2.4rem;
    height: 2.4rem;
    @media (--mobile) {
        width: 2.6rem;
        height: 2.6rem;
    }

    &:hover {
        color: var(--secondaryColor);
    }
}

.button--prev {
    transform: rotate(0.25turn);
}

.button--next {
    transform: rotate(-0.25turn);
}
</style>
