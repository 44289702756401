<template>
    <div class="dialogTop flex justify-end">
        <DialogEditButton
            v-if="showEdit"
            @click.native="$emit('edit')"
        />

        <DialogRemoveButton
            v-if="showRemove"
            @click.native="$emit('remove')"
        />

        <DialogCloseButton @click.native="$emit('close')" />
    </div>
</template>

<script>
import DialogCloseButton from './DialogCloseButton';
import DialogRemoveButton from './DialogRemoveButton';
import DialogEditButton from './DialogEditButton';

export default {
    components: {
        DialogCloseButton,
        DialogRemoveButton,
        DialogEditButton
    },

    props: {
        showRemove: {
            type: Boolean,
            default: false
        },

        showEdit: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="postcss" scoped>
.dialogTop {
    font-size: 4.4rem;
    min-height: 1em;
    margin-bottom: 1rem;
    @media (--mobile) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 0;
        margin-bottom: 0;
        background-color: #fff;
        padding: 1rem 2rem;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
        justify-content: flex-start;
    }
}
</style>
