import * as API from '../API.js';

export default {
    all () {
        return API.apiClient.get('/admin/courses');
    },

    get (courseId) {
        return API.apiClient.get(`/admin/courses/${courseId}`);
    },

    create (payload) {
        return API.apiClient.post('/admin/courses', payload);
    },

    update (courseId, payload) {
        return API.apiClient.put(`/admin/courses/${courseId}`, payload);
    },

    remove (courseId) {
        return API.apiClient.delete(`/admin/courses/${courseId}`);
    }
};
